







































































import {
  Component, Inject,
  InjectReactive,
  Vue,
  Watch,
} from 'vue-property-decorator';
import VbVideoStream from '@/components/VbVideoStream.vue';
import { Camera } from '@/models/entities/Camera';
import { Lesson } from '@/models/entities/Lesson';
import PolygonCellEditable from '@/views/polygons/components/PolygonCellEditable.vue';
import { Cell } from '@/models/entities/Cell';
import { Polygon } from '@/models/entities/Polygon';
import { AppStore } from '@/store/types/AppStore';
import { User } from '@/models/entities/User';

@Component({
  components: {
    PolygonCellEditable,
    VbVideoStream,
  },
})
export default class LessonViewCameraPartial extends Vue {
  @InjectReactive() lesson!: Lesson;
  @InjectReactive() polygonCameras!: Camera[];
  @InjectReactive() polygon!: Polygon | null;
  @InjectReactive() currentUserCellLeasesMappedByCellId!: { [cellId: number]: true };
  @InjectReactive() isAdmin!: boolean;
  @InjectReactive() isTeacher!: boolean;
  @Inject() currentUser!: User;

  camera: Camera | null = null;

  cellLayout: 'sidebar' | 'footer' = 'footer';

  @Inject() appStore!: AppStore;

  mounted() {
    this.updateCameraFromCurrentRoute();
  }

  getCameraIdFromCurrentRoute(): number {
    return parseInt(this.$route.params.cameraId, 10);
  }

  @Watch('$route')
  async updateCameraFromCurrentRoute() {
    if (this.camera) { // this rerenders video tag
      this.camera = null;
      await this.$nextTick();
    }
    const cameraId = this.getCameraIdFromCurrentRoute();
    this.camera = this.polygonCameras.find((c) => c.cameraId === cameraId) || null;
  }

  get connectedCell(): Cell | null {
    if (!this.camera || !this.camera.cellSequentialNumber || !this.polygon) {
      return null;
    }
    return this.polygon.cells.find((c) => c.sequentialNumber === this.camera?.cellSequentialNumber) || null;
  }

  get isAccessAllowed(): boolean {
    if (!this.camera) {
      return false;
    }

    if (this.isAdmin) {
      return true;
    }

    if (this.isTeacher && this.lesson.isLessonTime(new Date())) {
      return true;
    }

    if (this.camera.cellSequentialNumber && !this.connectedCell) {
      return false;
    }

    return Boolean(
      this.connectedCell
      && this.currentUserCellLeasesMappedByCellId[this.connectedCell.cellId]
      && this.lesson.isLessonTime(new Date()),
    );
  }
}
